.curved-text_text {
  font-family: 'bebas-neue', sans-serif;
  display: inline-block;
}
.curved-text_root {
  position: absolute;
  padding-bottom: 39px;
  transform-origin: bottom center;
}

.curved-text_non-root {
}
