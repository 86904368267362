.yard-and-display-screen-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  z-index: 1;
}

.yard-and-display_subtitle {
  font-size: 40px;
  line-height: 1;
}

.yard-and-display_white-bg {
  position: absolute;
  background-image: url('../assets/white-back-yard-and-display.png');
  width: 51vmax;
  height: 80vmax;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: top center;
  top: 0;
}

.yard-and-display_user-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2vh;
}

.yard-and-display_camera-icon {
  position: absolute;
  top: 45px;
  left: 60px;
  width: 25px;
  height: 25px;
  background-size: contain;
}

.yard-and-display_buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9vh;
}

.yard-and-display_required-players {
  color: #ba6f5c;
  font-size: 18px;
}

.yard-and-display_button-container {
  height: 8vh;
  display: flex;
  justify-content: center;
  margin: 6px 0;
  width: 100%;
  /*min-width: 320px;*/
}

.yard-and-display-screen-picture-container {
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 4vh;
  height: 21vh;
  background-color: transparent;
  border-radius: 24px;
  background-position: center;
  width: 21vh;
}

.yard-and-display-screen-button {
  width: 216px;
}

.yard-and-display-screen_curved-text {
  transform: rotate(5deg);
  width: 25vh;
  height: 24vh;
  text-transform: uppercase;
  font-size: 1em;
  margin-bottom: -28vh;
  letter-spacing: 0.7px;
  margin-left: 1.5vh;
  margin-top: 2vh;
}

.yard-and-display-screen_curved-text-anim {
  animation: yard-and-display-screen_text-activate 0.5s;
}
.yard-and-display-screen_curved-text-anim-bottom {
  animation: yard-and-display-screen_text-activate-bottom 0.5s;
}

.yard-and-display-screen_curved-text * {
  font-family: bebas-neue;
  transition: fill 0.5s;
}

.yard-and-display-screen_curved-text-bottom {
  width: 25vh;
  text-transform: uppercase;
  font-size: 1em;
  margin-top: -24vh;
  letter-spacing: 0.4vh;
  transform: rotate(-9deg);
  margin-left: 1.5vh;
}

@keyframes yard-and-display-screen_text-activate-bottom {
  50% {
    transform: scale(1.3) rotate(-9deg);
  }
}

@keyframes yard-and-display-screen_text-activate {
  50% {
    transform: scale(1.3) rotate(5deg);
  }
}

.yard-and-display-screen_curved-text-bottom * {
  font-family: bebas-neue;
  transition: fill 0.5s;
}

.yard-and-display-screen_switch-container {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 35vh;
}

.yard-and-display-screen_switch {
  background-image: url('../assets/switchDown.png');
  width: 11vh;
  height: 14vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.yard-and-display-screen_switch-flip {
  background-image: url('../assets/switchUp.png');
  width: 11.3vh;
  height: 14vh;
}

.yard-and-display-screen_required {
  font-size: 1.5em;
  transition: 1s;
  text-align: center;
  margin-top: -7vh;
  font-family: bebas-neue;
}

.yard-and-display-screen_required-anim {
  transform: scale(1.3);
  color: #08c675;
}

.yard-and-display_hidden-dev-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
}

.yard-and-display-test-text {
  color: red;
  font-size: 30px;
  margin-top: 20px;
  z-index: 1;
}
