.round-finished_avatar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.round-finished_avatar-avatar-container {
  height: 10vh;
  margin: 2vh 0;
}

.round-finished_avatars-container {
  /*background-size: contain;*/
  /*background-repeat: no-repeat;*/
}
