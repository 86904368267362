.active-drawing-simultaneous_content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
}

.active-drawing-simultaneous_sentence-puzzle-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 2vh 3vw;
  max-width: 80%;
}

.active-drawing-simultaneous_sentence-puzzle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 20pt;
  line-height: 1;
  font-weight: normal;
  height: 200px;
}
