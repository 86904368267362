.sketch_root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
}

.sketch_drawing-single-canvas-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sketch_bin-icon {
  width: 30px;
  height: auto;
  position: absolute;
  top: 10vh;
  right: 9vw;
}

.sketch_bin-popup-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sketch_bin-popup {
  position: absolute;
  top: 10vh;
  right: 5vw;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  height: 120px;
  width: 70px;
  justify-content: center;
  align-items: center;
  animation: sketch_bin-popup-enter 0.5s;
}

@keyframes sketch_bin-popup-enter {
  0% {
    height: 0;
  }
}

.sketch_bin-icon-open {
  width: 32px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./assets/trash-open.png');
}

.sketch_red-bg {
  width: 50px;
  height: 62px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./assets/small-red-spray.png');
  color: white;
}
.sketch_red-bg-text {
  margin-top: 10px;
  margin-left: 10px;
}
