.controller-vote-drawing_button-container {
  width: 100%;
}
.controller-vote-drawing_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*height: 100%;*/
  height: 80vh;
  justify-content: center;
}
.controller-vote-drawing_root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controller-vote-drawing_scroll-bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
