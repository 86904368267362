.single-canvas_root {
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 760px;
}

@media (max-width: 650px) {
  .single-canvas_root {
    width: 400px;
    height: 542.857142856px;
  }
}

@media (max-width: 400px) {
  .single-canvas_root {
    width: 350px;
    height: 475px;
  }
}

.single-canvas_view-root {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
