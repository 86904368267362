.brush-popover_icons-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 15vh;
  padding-top: 20px;
  padding-right: 3px;
}

.brush-popover_root {
  background-size: contain;
  background-repeat: no-repeat;
  width: 43px;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 6px 0px;
  padding-bottom: 1px;
  animation: brush-popover_root-anim 0.3s;
}

@keyframes brush-popover_root-anim {
  0% {
    width: 0;
    height: 0;
  }
}

.brush-popover_icon-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 17px;
  left: 7px;
}

.brush_line {
  background: black;
  animation: brush-popover_line-anim 0.3s forwards cubic-bezier(0.42, 0, 0.52, 1.44);
  border-radius: 3px;
}

@keyframes brush-popover_line-anim {
  0% {
    width: 0;
  }
  100% {
    width: 30px;
  }
}

.brush_line-container {
  height: 8vh;
  margin: 10px 0;
}
