.in-queue_root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.in-queue_loading-container {
  font-size: 32px;
  padding: 20px;
}
