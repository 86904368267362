.take-pic-dialog-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.take-pic-dialog-image-wrapper {
  display: flex;
  flex-direction: column;
}

.take-pic-dialog-image {
  border-radius: 24px;
}

.take-pic-dialog-close-icon-button {
  align-self: flex-start;
}
