.navigation-bar_root {
  padding: 1vh 2vw;
}

.navigation-bar_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.navigation-bar_header {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.navigation-bar_icon-container {
}
