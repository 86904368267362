.app-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.app-title {
  font-size: 56px;
  text-align: center;
  color: black;
}

.app-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  /*min-height: 200px;*/
  width: 100%;
}
