.active-drawing-simultaneous_sentence-puzzle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.sketch_toolbar-container {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
}
