.arrows-buttons_root {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arrows-buttons_button-img-left {
  width: 150px;
  height: auto;
}

.arrows-buttons_button-img-right {
  width: 100px;
  height: auto;
  margin-right: 50px;
}
