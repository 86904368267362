.scroll-bubbles_root {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 50px;
}

.scroll-bubbles_img {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
}
.scroll-bubbles_checkmark {
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
}
.scroll-bubbles_active-bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-bubbles_img-active {
  margin-bottom: -33%;
  width: 50px;
  height: 50px;
}
