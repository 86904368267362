.submittable-input_root {
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 125px;
  display: flex;
  position: relative;
  width: 315px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 450px) {
  .submittable-input_root {
    width: 400px;
    height: 170px;
  }
}

@media only screen and (min-width: 550px) {
  .submittable-input_root {
    width: 500px;
    height: 212px;
  }
}

.submittable-input_input {
  background: transparent;
  width: 50%;
  margin-left: -20%;
  font-size: 200%;
  border: 0;
  margin-top: 2%;
}

.submittable-input_warning {
  position: absolute;
  bottom: -10%;
  color: red;
}

.submittable-input_submit {
  background-image: url('../../assets/submitGuess.webp');
  width: 32%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  margin-top: 20px;
}
