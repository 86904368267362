.pick-word_confirmed-view-container {
  height: 70vh;
  width: 51vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.pick-word_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: space-between;*/
  /*max-height: 40vh;*/
  /*height: 100%;*/
}

.pick-word_sentence-puzzle-container {
  font-size: 20pt;
  line-height: 28pt;
  font-weight: normal;
  min-height: 75px;
  text-align: center;
}

.round-setup-pick-word_sentence-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 80%;
  text-align: center;
}

.round-setup-pick-word_root {
}

input:focus {
  outline: none;
}

.round-setup-pick-word_floating-words {
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 46vh;
}

.round-setup-pick-word_action-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 7vw;
  line-height: 1;
}

.round-setup-pick-word_floating-words-button {
  height: 12vh;
  flex-basis: 50%;
  width: 100%;
}

.round-setup-pick-word_floating-words-content {
  margin: 8vh 0;
}

.round-setup-pick-word_button-grid {
  display: flex;
  flex-wrap: wrap;
}

.pick-word_container {
}
