.avatar-with-points_root {
  font-family: 'bebas-neue', sans-serif;
  display: flex;
  flex-direction: row;
  padding-left: 10vw;
  align-items: center;
}

.avatar-with-points_image {
  width: 80px;
  height: auto;
}

.avatar-with-points_content-container {
  position: relative;
  margin-right: 50px;
}

.avatar-with-points_header-container {
  position: absolute;
}

.avatar-with-points_guesses-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.avatar-with-points_guesses {
  /*position: absolute;*/
  font-family: 'cityburnregular', sans-serif;
  width: 40px;
  height: auto;
  font-size: 20px;
  margin: 5px;
}

.avatar-with-points_summary-vote {
  /*position: absolute;*/
  font-family: 'cityburnregular', sans-serif;
  width: 90px;
  font-size: 30px;
  height: auto;
  margin: 5px;
}

.avatar-with-points_image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: auto;
}
