.game-button_root {
  position: relative;
  object-fit: fill;
  height: 100%;
  min-height: 84px;
  /*max-height: 80px;*/
  display: flex;
  width: 100%;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.8rem;
}

.game-button_background {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
  /* min-height is prolly a shitty hack to fix weird bugs, remove it and undertand the bugs */
  /* solution for this button not working as intended: Have static width and height based on media-query*/
  min-height: 84px;
  background-position: center;
}

.game-button_disabled {
  opacity: 0.5;
}

.game-button_text {
  position: absolute;
  margin-left: 13px;
  line-height: 1;
  margin-bottom: 7px;
}
