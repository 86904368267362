.loader-template_root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.loader-template-background-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80vh;
}

.loader-template_shade {
  filter: blur(5px);
  mix-blend-mode: multiply;
}

.loader-template-background-img-content {
  height: 110vh;
}
.loader-template_container {
  position: relative;
}
