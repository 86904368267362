.Toolbar__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  width: 100%;
  max-width: 85%;
}
.toolbar_popover-container-color {
  position: absolute;
  bottom: -4px;
  margin-left: -3px;
}
.toolbar_popover-container-brush {
  position: absolute;
  bottom: -14px;
  margin-left: -7px;
}

.Toolbar__innerBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toolbar_color-button {
  width: 33px;
  height: 34px;
  display: block;
  margin: 1px;
  border: 0;
  box-sizing: border-box;
  border-radius: 5px;
}

.toolbar_color-button-enter {
  animation: toolbar_color-button-appear 0.3s forwards;
  width: 0;
  height: 0;
}

@keyframes toolbar_color-button-appear {
  0% {
    opacity: 0;
    margin-left: 16px;
    height: 0;
    width: 0;
  }
  100% {
    height: 33px;
    width: 34px;
  }
}

.toolbar_color-button-active-enter {
  animation: toolbar_color-button-active-appear 0.3s forwards;
  width: 0;
  height: 0;
}

@keyframes toolbar_color-button-active-appear {
  0% {
    opacity: 0;
    margin-left: 16px;
    height: 0;
    width: 0;
  }
  100% {
    height: 33px;
    width: 34px;
    border: 2px solid black;
  }
}

.toolbar_buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin-left: 5%;
}

.Toolbar__actionButton {
  flex-basis: 21%;
  display: flex;
}

.toolbar_submit {
  background-image: url('../../assets/submitGuess.webp');
  width: 80px;
  margin-top: 20px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
}

.toolbar_color-picker-container {
  position: absolute;
  top: -144px;
}
